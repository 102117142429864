import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import styled from 'styled-components'
export default function DonutChart({
  labels,
  colors,
  series,
  width,
  height,
  legendPosition = 'right',
  ImageDonut,
  setImageDonut,
  legendShow,
}) {
  const options = {
    chart: {
      id: 'complaints-donut-chart',
    },
    tooltip: {
      enabled: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
    },
    labels: labels ? labels : [],
    legend: {
      show: legendShow ? false : true,
      horizontalAlign: 'center',
      position: legendPosition,
    },
    colors: colors,
    plotOptions: {
      pie: {
        donut: {
          size: '80%',
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: 'ทั้งหมด',
              fontSize: '10px',
              fontFamily: 'Prompt',
              fontWeight: 400,
              color: '#717171',
              formatter: function (w) {
                return w?.globals?.seriesTotals?.reduce((a, b) => a + b, 0)?.toLocaleString()
              },
            },
            value: {
              fontSize: '20px',
              fontFamily: 'Prompt',
              fontWeight: 500,
              color: '#150F0B',
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      dropShadow: {
        enabled: false,
      },
    },
    stroke: {
      show: false,
    },
  }
  console.log('options', options)
  return (
    <div>
      <CustomApex>
        <ReactApexChart
          options={options ? options : []}
          series={series ? series : []}
          type="donut"
          width={width ? width : 525}
          height={height ? height : 400}
        />
      </CustomApex>
    </div>
  )
}

const CustomApex = styled.div`
  .apexcharts-legend.apx-legend-position-right {
    position: absolute;
    left: auto;
    top: 0px;
    right: 5px;
    height: 310px;
  }
`
